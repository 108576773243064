<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">物料管理</p>
        </div>
        <div class="tableBox" style="top:50px;">
            <div class="views-main-search">
                <el-input v-model="zwm" placeholder="请输入名称或代码" class="iww" ></el-input>
                <el-input v-model="ywm" placeholder="请输入英文名" class="iww" ></el-input>
                <!-- <el-input v-model="dm" placeholder="请输入代码" class="iw" ></el-input> -->
                <el-input v-model="ghs" placeholder="请输入供货商" class="iw" ></el-input>
                <el-button type="primary" @click="searchPost">查询</el-button>
                
                <el-button type="success" @click="addPost('1')" >添加物料</el-button>
                <el-button type="primary" @click="downloadTemplet" class="fr">下载模板</el-button>
                <el-button type="warning" @click="uploadTemplet" class="fr">导入模板</el-button>
            </div>
            <div class="views-main-body">
                <el-table :data="postList" stripe style="width: 100%"> 
                    <el-table-column prop="Name" label="图片">
                        <template slot-scope="scope">
                            <el-image v-if="scope.row.Files.length>0"
                                style="width: 20px; height: 20px;margin-top:3px;"
                                :src="scope.row.Files[0].Path" 
                                :preview-src-list="[scope.row.Files[0].Path]">
                            </el-image>
                        </template>
                    </el-table-column>
                    <el-table-column prop="Name" label="中文名"></el-table-column>
                    <el-table-column prop="EnglishName" label="英文名"></el-table-column>
                    <el-table-column prop="Code" label="代码"></el-table-column>
                    <el-table-column prop="Standards" label="规格"></el-table-column>
                    <el-table-column prop="Unit" label="计量单位"></el-table-column>
                    <el-table-column prop="Supplier" label="供货商"></el-table-column>
                    <el-table-column prop="InWareHouseCount" label="库存数量">
                        <template slot-scope="scope">
                            <span v-if="scope.row.InWareHouseCount">{{scope.row.InWareHouseCount}}</span>
                            <span v-else>0</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" width="300">
                        <template slot-scope="scope">
                            <!-- <el-button @click="thresholdPost(scope.row)" type="text" size="small" style="color:#67C23A">阈值设置</el-button> -->
                            <el-button @click="editPost(scope.row)" type="text" size="small" style="color:#E6A23C">编辑</el-button>
                            <el-button @click="delPost(scope.row)"  type="text" size="small" style="color:#F56C6C">删除</el-button>
                            <el-button @click="logPost(scope.row)" type="text" size="small" style="color:#E6A23C">出入库记录</el-button>
                            <el-button @click="InOutPost(scope.row)" type="text" size="small" style="color:#409EFF" v-if="scope.row.InWareHouseCount">库存查看</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next"  :current-page="pageIndex" :page-size="pageSize" :total="Total" class="pg" @current-change="PGChange"></el-pagination>
            </div>
        </div>
        <el-dialog :title="doTypeStr" :visible.sync="postModal" width="1200px" :close-on-click-modal="false">
            <el-form :model="postInfo" status-icon :rules="postrules"  ref="uForm" label-width="100px" class="demo-uForm">
                <el-form-item label="中文名" prop="name">
                    <el-input v-model="postInfo.name" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="英文名" prop="englishname">
                    <el-input v-model="postInfo.englishname" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="代码" prop="code">
                    <el-input v-model="postInfo.code" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="规格" prop="standards">
                    <el-input v-model="postInfo.standards" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="计量单位" prop="unit">
                    <el-input v-model="postInfo.unit" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="供货商" prop="supplier">
                    <el-input v-model="postInfo.supplier" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="图片">
                    <el-upload
                            class="avatar-uploader"
                            action="https://jsonplaceholder.typicode.com/posts/"
                            :show-file-list="false"
                            :http-request="uploadFunD"
                            :before-upload="beforeAvatarUploadD">
                            <img v-if="imageUrlD" :src="imageUrlD" class="avatar">
                            <el-button v-else type="primary" size="mini">添加图标</el-button>
                        </el-upload>
                </el-form-item>
                <el-form-item>  
                    <el-button type="primary" @click="submitPostForm()">提交</el-button>
                    <el-button @click="postModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <el-dialog title="物料库存查看" :visible.sync="storeModal" width="1200px" :close-on-click-modal="false" top="30px" @close="storeModalClose">
                <el-table :data="storeList" stripe style="width: 100%" :height="dialogMaxHeigh" > 
                    <el-table-column prop="GoodName" label="货名"></el-table-column>
                    <el-table-column prop="ShelvingCode" label="货架号"></el-table-column>
                    <el-table-column prop="ShelvingRowIndex" label="行号"></el-table-column>
                    <el-table-column prop="ShelvingRowIndex" label="列号"></el-table-column>
                    <el-table-column prop="Index" label="货格号"></el-table-column>
                    <el-table-column prop="GoodCount" label="存放数量"></el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next"  :current-page="pageIndexS" :page-size="pageSizeS" :total="TotalS" class="pg" @current-change="PGChangeS"></el-pagination>
                <div style="height:20px;width:100%;float:left;"></div>
        </el-dialog>


        <el-dialog :title="doTypeStr" :visible.sync="goodsThresholdModal" width="800px" :close-on-click-modal="false">
            <p style="font-size:14px;color:#666666;margin-bottom:20px;" v-if="saveItem">请设置<span style="color:#E6A23C;font-weight: bold;"> {{saveItem.Name}} </span>在仓库的库存阈值，当物料出库后低于此阈值时会提醒库管及时补货(0视为不提醒)</p>
            <div style="overflow:hidden;margin-bottom:20px;" v-for="(item,index) in storeThresholdList" :key="item.SnowID">
                <p style="font-size:14px;font-weight: bold;color:#F56C6C;margin-bottom:5px;">{{item.Name}}</p>
                <el-input v-model="item.threshold" autocomplete="off" placeholder="请输入阈值" style="width:80%;margin-right:20px;"></el-input>
                <el-button type="primary" @click="submitThresholdForm(index)">保存</el-button>
            </div>
        </el-dialog>

        <el-dialog title="出入库记录查看" :visible.sync="logModal" width="1200px" :close-on-click-modal="false" top="30px" @close="storeModalClose">
            <div style="width:100%;overflow:hidden;margin-bottom:20px;">
                <el-select v-model="logType" placeholder="出入库类型"   class="iww" >
                        <el-option label="入库记录" value="1" ></el-option>
                        <el-option label="调拨记录" value="3" ></el-option>
                        <el-option label="出库记录" value="4" ></el-option>
                </el-select>
                <el-date-picker v-model="logTimes" type="datetimerange" range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间" class="iwss"></el-date-picker>
                <el-button type="primary" @click="searchPostL">查询</el-button>
            </div> 
            <el-table :data="logList" stripe style="width: 100%" :height="dialogMaxHeigh" > 
                <el-table-column prop="WAREHOUSENAME" label="仓库"></el-table-column>
                <el-table-column prop="GoodName" label="货名"></el-table-column>
                <el-table-column prop="GoodCount" label="入库数量" v-if="logType=='1'"></el-table-column>
                <el-table-column prop="ONEMONEY" label="入库单价" v-if="logType=='1'"></el-table-column>
                <el-table-column prop="SAVECODE" label="入库货格" v-if="logType=='1'"></el-table-column>
                <el-table-column prop="CreateTime" label="入库时间" v-if="logType=='1'" width="165"></el-table-column>
                <el-table-column prop="SOURCE" label="货物来源" v-if="logType=='1'"></el-table-column>

                <el-table-column prop="GoodCount" label="调入数量" v-if="logType=='3'"></el-table-column>
                <el-table-column prop="SAVECODE" label="调入货格" v-if="logType=='3'"></el-table-column>
                <el-table-column prop="OLDSAVECODE" label="被调货格" v-if="logType=='3'"></el-table-column>

                <el-table-column prop="GoodCount" label="出库数量" v-if="logType=='4'"></el-table-column>
                <el-table-column prop="SAVECODE" label="出库货格" v-if="logType=='4'"></el-table-column>
                <el-table-column prop="Target" label="出库去向" v-if="logType=='4'"></el-table-column>

                <el-table-column prop="BZ" label="备注"></el-table-column>
            </el-table>
            <el-pagination background layout="total, prev, pager, next"  :current-page="pageIndexL" :page-size="pageSizeL" :total="TotalL" class="pg" @current-change="PGChangeL"></el-pagination>
            <div style="height:20px;width:100%;float:left;"></div>
        </el-dialog>
        <el-dialog title="选择文件" :visible.sync="chooseFileModal" width="400px"  :append-to-body="true">
            <el-upload
              ref="upload"
              class="upload-demo"
              drag
              :multiple="false"
              :limit="1"
              accept=".xls,.xlsx"
              action=""
              :http-request="uploadFun"
              >
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
              <div class="el-upload__tip" slot="tip">只能上传excel文件</div>
            </el-upload>
    </el-dialog>
    </div>
</template>
<script>
var h=document.body.clientHeight
export default {
  name: "UnitManage",

  data() {
    var name = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输入中文名"));
      } else {
        callback();
      }
    };
    var englishname = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输入英文名"));
      } else {
        callback();
      }
    };
    var code = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输入代码"));
      } else {
        callback();
      }
    };
    var standards = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输入规格"));
      } else {
        callback();
      }
    };
    var unit = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输入计量单位"));
      } else {
        callback();
      }
    };
    var supplier = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输入供货商"));
      } else {
        callback();
      }
    };  

    return {
        ckid:"",
        dialogMaxHeigh:0,

        canDo:true,
        showPagination:false,
        zwm:"",
        ywm:"",
        dm:"",
        ghs:"",

        postList:[],
        pageIndex:1,
        pageSize:10,
        Total:0,

        addFrom:"",
        doTypeStr:"",
        doType:"",
        postModal:false,
        postInfo:{},
        postrules: {
            name: [{ validator: name, trigger: "blur" }],
            englishname: [{ validator: englishname, trigger: "blur" }],
            code: [{ validator: code, trigger: "blur" }],
            standards: [{ validator: standards, trigger: "blur" }],
            unit: [{ validator: unit, trigger: "blur" }],
            supplier: [{ validator: supplier, trigger: "blur" }],
        },

        imageFile:null,
        imageUrlD:"",



        storeModal:false,
        nowGoods:null,
        storeList:[],
        pageIndexS:1,
        pageSizeS:10,
        TotalS:0,
        
        saveItem:null,


        storeThresholdList:[],
        goodsThresholdModal:false,


        logModal:false,
        logList:[],
        pageIndexL:1,
        pageSizeL:10,
        TotalL:0,
        logType:"1",
        logTimes:[],

        chooseFileModal:false,
    };
  },
  mounted() {
    this.ckid=this.$store.state.ckid
    this.dialogMaxHeigh = h -300
    this.loadItem=null
    this.getPostList()
    // this.getAllStoreList()
  },
  methods: {
    storeModalClose(){
        this.nowGoods=null  
    },
    getAllStoreList(){
        var params={
            snowID:this.ckid
        }
        this.$storeAxios.post("WareHouse/GetDataList", params)
            .then((response) => {
                if (response.data.SUCCESS) {
                    var list =response.data.DATA
                    for(var i=0;i<list.length;i++){
                        var d=list[i]
                        d.threshold=0
                        d.InCount=0
                        this.storeThresholdList.push(d)
                    }
                } 
            })
    },
    getPostList(){
        this.showLoading("请稍后")
        var params={
            pageIndex:this.pageIndex,
            pageSize:this.pageSize,
            name:this.zwm,
            englishName:this.ywm,
            code:this.dm,
            supplier:this.ghs,
            wareHouseSnowID:this.ckid,
        }
        this.$storeAxios.post("Good/GetDataList", params)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                this.postList=response.data.DATA
                this.Total=response.data.TOTALCOUNT
            } else {
                this.postList=[]
                this.Total=0
                this.$message.error(response.data.MSG)
            }
            })
            .catch((error) => {
                this.hideLoading()
                this.postList=[]
                this.Total=0
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                }
            });
    },
    searchPost(){
        this.pageIndex=1
        this.getPostList()
    },
    PGChange(e){
        this.pageIndex=e
        this.getPostList()
    },
    addPost(from){
        this.addFrom=from
        this.postInfo={
            name:"",
            englishname:"",
            code:"",
            standards:"",
            unit:"",
            supplier:"",
        }
        this.imageFile=null
        this.imageUrlD=""
        this.doTypeStr="添加物料"
        this.doType="1"
        this.postModal=true
    },
    sendAdd(){
        this.canDo=false
        this.showLoading("请稍后")
        this.$storeAxios.post("Good/InsertData", this.postInfo)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已添加")
                    if(this.imageFile){
                        this.addImg(response.data.DATA)
                    }
                    this.getPostList()
                    this.postModal=false
                    if(this.addFrom=="2"){
                        this.searchGoodsStr=this.postInfo.code
                        this.getGoodsList()
                    }
                } else {
                    this.$message.error(response.data.MSG)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                }
             });
    },
    editPost(item){
        this.imageFile=null
        this.imageUrlD=""
        this.postInfo={
            snowID:item.SnowID,
            name:item.Name,
            englishname:item.EnglishName,
            code:item.Code,
            standards:item.Standards,
            unit:item.Unit,
            supplier:item.Supplier,
        }
        this.doTypeStr="修改物料"
        this.doType="2"
        this.postModal=true
        if(item.Files.length>0){
            this.imageUrlD=item.Files[0].Path
        }
    },
    sendEdit(){
        this.canDo=false
        this.showLoading("请稍后")
        this.$storeAxios.post("Good/UpdateData", this.postInfo)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已修改")
                    this.postModal=false
                    if(this.imageFile){
                        this.addImg(this.postInfo.snowID)
                    }
                    this.getPostList()
                } else {
                    this.$message.error(response.data.MSG)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                }
            });
    },
    submitPostForm(){
        if (this.canDo) {
            this.$refs.uForm.validate((valid) => {
                if (valid) {
                    if(this.doType=="1"){
                        this.sendAdd()
                    }else if(this.doType=="2"){
                        this.sendEdit()
                    }
                } else {
                    return false;
                }
            });
        }
    },


    beforeAvatarUploadD(file) {
        const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
        const isLt2M = file.size / 1024 / 1024 < 2;

        if (!isJPG) {
        this.$message.error('图片只能是 JPG/PNG  格式!');
        }else if (!isLt2M) {
        this.$message.error('图片大小不能超过 2MB!');
        }else{
            var _URL = window.URL || window.webkitURL;
            this.imageUrlD = _URL.createObjectURL(file);
            this.imageFile=file
        }
        return isJPG && isLt2M;
    },
    uploadFunD(file){
        //console.log(file)
    },

    
    delPost(item){
        if(this.canDo){
            this.$confirm('确定删除物料：'+item.Name+'？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.sendDel(item.SnowID)
            }).catch(() => {
                this.$message.info("已取消")    
            });
        }
    },
    addImg(id){
        var fd = new FormData();
        fd.append("file", this.imageFile);
        this.$storeUpload.post("File/UpLoadFile?SourceSnowID="+id+"&FileType=1&DeleteOld=1", fd)
        .then((response) => {
                if (response.data.SUCCESS) {
                    this.getPostList()
                }
            })
    },
    sendDel(id){
        this.canDo=false
        this.showLoading("请稍后")
        this.$storeAxios.post("Good/DeleteData", {snowid:id})
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已删除")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.$message.error(response.data.MSG)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true  
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                } 
            });
    },

    /**
     * 查看库存
     */
    InOutPost(item){
        this.nowGoods=item
        this.pageIndexS=1
        this.getGoodsStores()
        this.storeModal=true
    },
    getGoodsStores(){
        this.showLoading("请稍后")
        var params={
            pageIndex:this.pageIndexS,
            pageSize:this.pageSizeS,
            goodSnowID:this.nowGoods.SnowID,
            WareHouseSnowID:this.ckid,
        }
        this.$storeAxios.post("ColumnGrid/GetDataList", params)
            .then((response) => {
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.storeList=response.data.DATA
                    this.TotalS=response.data.TOTALCOUNT
                } else {
                    this.storeList=[]
                    this.TotalS=0
                    this.$message.error(response.data.MSG)
                }
            })
            .catch((error) => {
                this.hideLoading()
                this.storeList=[]
                this.TotalS=0
                if (error.isAxiosError) {
                    if(error.response.status==401){
                        this.logout()
                    }
                }
            });
    },
    searchPostS(){
        this.pageIndexS=1
        this.getGoodsStores()
    },
    PGChangeS(e){
        this.pageIndexS=e
        this.getGoodsStores()
    },



    /**
     * 阈值设置
     */
    thresholdPost(item){
        if(this.canDo){
            this.saveItem=item
            this.doTypeStr=item.Name+"-阈值设置"
            this.getGoodsThreshold()
        }
    },
    getGoodsThreshold(){
        var params={
            goodSnowID:this.saveItem.SnowID,
        }
        this.canDo=false
        this.showLoading("请稍后")
        this.$storeAxios.post("WarningSetting/GetDataList", params)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.setGoodsThreshold(response.data.DATA)
                }else{
                    this.setGoodsThreshold([])
                }
            })
            .catch((error) => {
                this.setGoodsThreshold([])
                this.hideLoading()
                this.canDo=true
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                }
            });
    },
    setGoodsThreshold(list){
        var obj={}
        for(var i=0;i<this.storeThresholdList.length;i++){
            var d=this.storeThresholdList[i]
            d.threshold=0
            obj[d.SnowID]=d
        }

        for(var j=0;j<list.length;j++){
            var t=list[j]
            obj[t.WareHouseSnowID].threshold=t.GoodCount
            obj[t.WareHouseSnowID].InCount=t.WareHouseCount
        }

        var nl=[]
        for(var item in obj){
            nl.push(obj[item])
        }
        this.storeThresholdList=nl
        this.goodsThresholdModal=true
    },
    submitThresholdForm(index){
        var num=this.storeThresholdList[index].threshold
        if(typeof num != 'number' && isNaN(num)){
            this.$message.error("阈值只能是数字")
        }else if(!/^\+?(0|[1-9][0-9]*)$/.test(num)){
            this.$message.error("只能输入大于等于0的整数")
        }else{
            var params={
                wareHouseSnowID:this.storeThresholdList[index].SnowID,
                goodSnowID:this.saveItem.SnowID,
                goodCount:num,
            }
            this.sendThresholdSave(params)
        }
    },
    sendThresholdSave(params){
        this.canDo=false
        this.showLoading("请稍后")
        this.$storeAxios.post("WarningSetting/InsertOrUpdateData", params)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("设置成功！")
                } else {
                    this.$message.error(response.data.MSG)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                }
            });
    },
    /**
     * 出入库记录
     */
    logPost(item){
        this.nowGoods=item
        this.searchPostL()
        this.logModal=true
    },
    getGoodsLogList(){
        this.showLoading("请稍后")
        var st=''
        var et=''
        if(this.logTimes && this.logTimes.length==2){
            st=this.comjs.getDateStrByDate(this.logTimes[0])
            et=this.comjs.getDateStrByDate(this.logTimes[1])
        }
        var params={
            pageIndex:this.pageIndexL,
            pageSize:this.pageSizeL,
            goodSnowID:this.nowGoods.SnowID,
            WareHouseSnowID:this.ckid,
            status:this.logType,
            startTime:st,
            endTime:et,
        }
        this.$storeAxios.post("GoodIn/GetDataList", params)
            .then((response) => {
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.logList=response.data.DATA
                    this.TotalS=response.data.TOTALCOUNT
                } else {
                    this.logList=[]
                    this.TotalL=0
                    this.$message.error(response.data.MSG)
                }
            })
            .catch((error) => {
                this.hideLoading()
                this.logList=[]
                this.TotalL=0
                if (error.isAxiosError) {
                    if(error.response.status==401){
                        this.logout()
                    }
                }
            });
    },
    searchPostL(){
        this.pageIndexL=1
        this.getGoodsLogList()
    },
    PGChangeL(e){
        this.pageIndexL=e
        this.getGoodsLogList()
    },
    downloadTemplet(){
      window.open("file/物料导入.xlsx")
    },
    uploadTemplet(){
      this.chooseFileModal=true
    },
    uploadFun(f){
      //this.showLoading("请稍后...")
      var fd = new FormData();
      //console.log(f)
      fd.append("file", f.file);
      this.$storeUpload.post("Good/UpLoadGoods", fd)
        .then((response) => {
          //console.log(response)
         this.hideLoading()
          if (response.data.SUCCESS) {
             this.$message.success("导入完成")
             this.$refs.upload.clearFiles()
             this.chooseFileModal=false
             this.getPostList()
          }else{
             this.$message.error("导入失败")
          }
        })
        .catch((error) => {
            this.hideLoading()
        });
    },
  },
  computed: {
  },
  watch: {
  },
};
</script>
<style scoped>


</style>